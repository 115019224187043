const { REGULATIONS_PAGE } = require('./routes')

const HOST = process.env.NEXT_PUBLIC_HOST

const Business = {
    owner: 'POLMARKET',
    ownerAlt: 'Grupa Polmarket',
    logo: 'https://drive.google.com/uc?export=view&id=1ATFGGA43ZHT--pE-wiiYfTYQ84xD5Yh2',
    image: 'https://drive.google.com/uc?export=view&id=1CPkfZqKtmuTCULLF3xHWHUq4-GtCPBYu',
    firm: 'POLMARKET',
    nip: '9730672604',
    regon: '380872518',
    name: 'Samochody.pl',
    www: 'https://samochody.pl/',
    email: 'Kontakt@samochody.pl',
    phone: '+48 530 606 040',
    address: 'ul. Poznańska 20B,\n65-128 Zielona Góra',
    host: HOST,
    hosting: 'kei',
    copyright: 'Copyright ©' + new Date().getFullYear() + '.',
    facebook: 'https://www.facebook.com/portalsamochodypl/',
    twitter: 'https://twitter.com/samochody_pl',
    instagram: 'https://www.instagram.com/pl_samochody/',
    regulations: HOST + REGULATIONS_PAGE,
    invoice: {
        firm: 'POLMARKET PL SP Z O.O. SPÓŁKA KOMANDYTOWA',
        address: 'ul. Poznańska 20B\n65-137 Zielona Góra',
        NIP: '9731059426'
    },
}

module.exports = Business