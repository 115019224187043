import Navbar from '@components/shared/Navbar'
import Footer from '@components/shared/Footer'
import ArticleSection from '@components/html/ArticleSection'

import styles from 'styles/Main.module.css'

const MainLayout = ({ children, extraLinks, footerArticle,
    logoonly, navdown, navspace, nofooterdiv, nonavshadow }) => {

    return (
        <main className={styles.main}>
            { navspace && <NavSpace /> }
            <Navbar logoOnly={logoonly} navdown={navdown} nonavshadow={nonavshadow} />
            { children }
            { footerArticle &&
                <ArticleSection article={footerArticle} id="artykul-stopka" /> }
            { extraLinks }
            <Footer nodiv={nofooterdiv} />
        </main>
    )
}

export function NavSpace (props) {
    return <div className={styles['nav-space']} {...props} />
}

MainLayout.NavSpace = NavSpace

export default MainLayout