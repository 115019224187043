import mix from '@utils/styles/mix'
import styles from './ColumnsCss.module.css'

function Columns ({ children, className, gap = 20, style, variant, ...rest }) {
    const _className = mix([styles.columns, styles[variant], className])
    return (
        <div
            className={_className}
            style={{ gap, ...(style || {}) }}
            {...rest}
        >
            {children}
        </div>
    )
}

function Column ({ center, children, isSmall, ...rest }) {
    const className = mix([styles.column, center && styles.center])
    return (
        <div
            className={className}
            {...isSmall ? {"data-is-small":"true"} : {}}
            {...rest}
        >
            {children}
        </div>
    )
}

function Enum ({ children, lp, ...rest }) {
    return <div className={styles.enum} {...rest}>
        <span className={styles.lp}>{lp}</span>
        {children}
    </div>
}

export {
    Columns,
    Column,
    Enum,
}