import Link from 'next/link'
import Container from '@components/layout/Container'
import * as Columns from '@components/layout/ColumnsCss'

import Logo from './Logo'
import b from '@constants/Business'
import useT from '@hooks/useTranslation'

import {
    // FORUM_PAGE,
    CONTACT_PAGE,
    NEWS_PAGE,
    FIRM_BASE_PAGE,
    ABOUT_US_PAGE,
    PRICING_PAGE,
    API_DOCS_PAGE,
    PRIVACY_PAGE,
    REGULATIONS_PAGE,

} from '@constants/routes'

import { CAR_LINK_BASE } from '@constants/searchFormLinks'
import blogCategory from '@utils/links/blogCategory'

import styles from './Footer.module.css'

const HOST = process.env.NEXT_PUBLIC_HOST

// TODO: CSS version of columns -> server side rendering:
function Footer ({ nodiv }) {
    return <footer className={styles.footer}>
        <Container>
            { !nodiv && <hr/> }

            <Columns.Columns variant="center-mobile">
                <Columns.Column>
                    <Logo />
                    <p>{b.copyright} samochody.pl<br/>{useT('Wszelkie prawa zastrzeżone')}</p>
                </Columns.Column>

                <Columns.Column>
                    <h3>Samochody.pl</h3>
                    <_Link href={HOST + CONTACT_PAGE}>{useT('Pomoc i zgłoszenia')}</_Link>
                    <_Link href={HOST + ABOUT_US_PAGE}>{useT('O nas')}</_Link>
                    <_Link href={HOST + PRICING_PAGE}>{useT('Ceny ogłoszeń')}</_Link>
                    <_Link href={HOST + API_DOCS_PAGE}>{useT('API samochody.pl')}</_Link>
                    <_Link href={HOST + PRIVACY_PAGE}>{useT('Polityka prywatności')}</_Link>
                    <_Link href={HOST + REGULATIONS_PAGE}>{useT('Regulamin serwisu')}</_Link>
                    <br/>
                    <div style={{display:'flex',gap:16}}>
                        <_Link href={b.facebook}><img alt="Facebook" src={HOST + "/new-svg/facebook.svg"} /></_Link>
                        <_Link href={b.twitter}><img alt="Twitter" src={HOST + "/new-svg/twitter.svg"} /></_Link>
                        <_Link href={b.instagram}><img alt="Instagram" src={HOST + "/new-svg/instagram.svg"} /></_Link>
                    </div>
                </Columns.Column>

                <Columns.Column>
                    <h3>{useT('Na skróty')}</h3>
                    <_Link href={HOST + NEWS_PAGE}>{useT('Wiadomości motoryzacyjne')}</_Link>
                    <_Link href={HOST + blogCategory('tech-data')}>{useT('Dane techniczne')}</_Link>
                    <_Link href={HOST + FIRM_BASE_PAGE}>{useT('Baza firm')}</_Link>
                    <_Link href={HOST + [CAR_LINK_BASE,'uzywane'].join('/')}>{useT('Samochody używane')}</_Link>
                    <_Link href={HOST + [CAR_LINK_BASE,'nowe'].join('/')}>{useT('Samochody nowe')}</_Link>
                </Columns.Column>
            </Columns.Columns>
        </Container>
    </footer>
}

function _Link ({ children, href = '' }) {
    const isExternal = href.startsWith('http')
    return <Link href={`${isExternal ? '' : HOST}${href}`} prefetch={false}><>{children}</></Link>
}

export default Footer